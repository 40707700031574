// Theme color
$primary-color: #34a853;
$primary-light: #7fc3a8;
$secondary-color: #fbbc05;
$secondary-light: #fae692;

//Alerts color
$danger-color: #fe5050;
$info-color: #3eaeff;
$warning-color: #ff9700;

// input

$border-color: #e7ecf2;
$input-filled-color: #66737f;
$input-placeholder-color: #b8c1cc;

// font colors
    
$font-common-dark: #66737f;
$font-header-dark: #1d1e3a;

// gray

$table-row-gray: #f2f5f8;
$background-color: #f2f5f8;

$border-radius: 0.313rem;

$footer-color: #769099;
