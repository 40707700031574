@import 'themes';
@import '~@nebular/theme/styles/globals';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */
body {
  font-family: "GoogleSans-Medium", sans-serif !important;
  box-sizing: border-box;
}
@import "styles/color-palatte";
@import "styles/helper";
@import "styles/common";
@import "styles/override-resouce-view";


/*Common Classes*/
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 0.313rem;
}

::-webkit-scrollbar {
  width: 0.313rem;
  height: 0.313rem;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.313rem;
  background-color: #d5d5d5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.nb-theme-corporate .limit-selection nb-select .select-button {
  width: 5rem;
  min-width: 5rem !important;
  height: 2.188rem;
  line-height: 1rem !important;
  font-weight: 500 !important;
}
.nb-theme-corporate [nbButton] {
  font-weight: normal !important;
}
.toggle-switcher {
  margin: 0 !important;
}
.nb-theme-corporate nb-toggle .toggle.checked .toggle-switcher {
  left: calc(100% - 1.5rem) !important
}
.nb-theme-corporate nb-toast .content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.nb-theme-corporate a:focus {
  outline: none !important;
}
nb-accordion-item{
  border: 1px solid #E7ECF2;
  border-radius: 5px;
  nb-accordion-item-header{
    border-bottom-width: 0;
    p{
      margin: 0 10px;
    }
    button{
      margin-left: auto;
      position: relative;
      z-index: 99;
      &.ml-10{
        margin-left: 10px;
      }
      &.border-btn{
        background-color: transparent;
        border-color: $primary-color;
      }
    }
  }
}
.nb-theme-corporate nb-accordion-item-header .expansion-indicator {
  display: none;
}
.nb-theme-corporate nb-accordion-item-header {
  border-bottom: none;
}

.nb-theme-corporate nb-alert {
  padding: 10px;
}

.appearance-filled.status-basic[nbButtonToggle] {
  background-color: $primary-light !important;
  color: white !important;
  border-color: $primary-light !important;
  border-left-color: white !important;
}
.appearance-filled.status-primary[nbButtonToggle]:hover,
.appearance-filled.status-primary[nbButtonToggle]:focus {
  border-color: $primary-color !important;
  border-left-color: white !important;
}
//header css
.body-scroll{
  overflow: hidden;
  background-color: #013243;
}
#rotate-menu{
  width: 100vw;
  overflow: hidden;
  transition: transform 1s cubic-bezier(.2,1,.3,1),-webkit-transform 1s cubic-bezier(.2,1,.3,1);
  &.openmenu{
    transition: transform 1s cubic-bezier(.2,1,.3,1),-webkit-transform 1s cubic-bezier(.2,1,.3,1);
    transform-origin: 50vw 50vh;
    transform: perspective(1000px) translate3d(0, 50vh, 0) rotateX(30deg);
    pointer-events: none;
    @media screen and (max-width: 1440px) {
      transform: perspective(1000px) translate3d(0, 65vh, 0) rotateX(
          30deg
      );
    }
  }
}

.zindex-0 {
  z-index: -1;
}

.zindex-999 {
  z-index: 10;
  position: relative;
}

#header {
  .layout, nb-layout-header {
    background-color: transparent;
  }

  .layout-container {
    display: none;
  }

  nav {
    height: auto;
  }
  .head {
    button {
      svg {
        fill: #FFFFFF;
      }
    }
  }
}
.mr-0{
  margin-right: 0 !important;
}
.page-header {
  font-weight: bold;
  font-size: $h4-header-font-size;
  line-height: $header-line-height;
  color: $font-header-dark;
}
.nb-theme-corporate nb-accordion-item-header {
  padding: 0.825rem 1rem !important;
  p {
    font-weight: 500 !important;
  }
}
.nb-theme-corporate nb-accordion-item-body .item-body {
  padding: 34px;
}
.floor-toolbar nb-accordion-item-body .item-body {
  padding: 1rem !important;
}
.nb-theme-corporate nb-option-list.size-medium nb-option,
.nb-theme-corporate nb-option-list.size-large nb-option {
  font-weight: normal !important;
}
.nb-theme-corporate nb-select .select-button {
  min-width: 0;
}
.nb-theme-corporate nb-select.size-medium .select-button,
.nb-theme-corporate nb-select.size-large .select-button{
  font-weight: normal;
}
.nb-theme-corporate .phone-input .nb-form-field-prefix-medium,
.nb-theme-corporate .phone-input .nb-form-field-prefix-large {
  width: 7rem;
}
.nb-theme-corporate .phone-input nb-select.appearance-outline.size-medium .select-button,
{
  padding: 0.4375rem 0.4375rem;
}
.nb-theme-corporate .phone-input nb-select.appearance-outline.size-large .select-button {
  padding: 0.6875rem 1rem;
}
.nb-theme-corporate .phone-input .nb-form-field-control-with-prefix [nbInput].size-medium,
.nb-theme-corporate .phone-input .nb-form-field-control-with-prefix [nbInput].size-large {
  padding-left: 3.5rem;
}
.custom-option-list {
  width: 300px !important;
  font-weight: normal;
}
.custom-option-overlay {
  left: 75px !important;
}
.ck.ck-toolbar {
  background-color: white !important;
  border: 1px solid $border-color !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  height: 60px;
  border: 1px solid $border-color !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border: 1px solid $border-color !important;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  box-shadow: none !important;
}
.ck-rounded-corners .ck.ck-button {
  color: $input-placeholder-color !important;
}
.ck .ck-editor__main {
  p {
    color: $input-placeholder-color !important;
  }
}
.ck .ck-placeholder:before {
  color: $input-placeholder-color !important;
}
nb-spinner {
  z-index: 10 !important;
}
.logout-spinner nb-spinner {
  background-color: $primary-color !important;
  color: white !important;
}
.logout-spinner nb-spinner.status-primary .spin-circle {
  border-top-color: #ffffff;
  border-right-color: transparent;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 8px;
  border: 5px solid $primary-color;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary-color transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.nb-theme-corporate .label {
  width: 100%;
}

nb-theme-corporate nb-icon svg {
  height: 100%;
  width: 100%;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.setting-tables{
  .expansion-indicator {
    display: none;
  }
}
.pt-0 {
  .item-body{
    padding-top: 0 !important;
  }
}
.table-card {
  background: $border-color 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  padding: 15px;
  margin-top: 10px;
}
.d-block{
  display: block;
}
.w-100{
  width: 100%;
}


.align-self-center{
  align-self: center;
}
.common-select{
  width: 100%;
  max-width: 100% !important;
  .select-button{
    //opacity: 0 ;
    padding: 11px !important;
  }
}
.scroll{
  .section-list-wrap{
    max-height: calc(100vh - 470px);
    overflow-y: auto !important;
    margin: 0px -34px;
    padding: 0px 34px;
    .tabs-header{
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 99;
    }
  }
}
.nb-theme-corporate nb-tabset .tab-link {
  font-size: 16px;
  font-weight: 400;
  padding: 1rem 1.25rem;
  word-break: keep-all;
  white-space: nowrap;
}
.icon-input{
  position: relative;
  nb-icon{
    position: absolute;
    top: 11px;
  }
  &.right{
    nb-icon {
      right: 10px;
    }
    input{
      padding-right: 40px;
    }
  }
  &.left{
    nb-icon {
      left: 10px;
    }
    input{
      padding-left: 40px;
    }
  }
}
.ql-toolbar.ql-snow {
  border: 1px solid #E7ECF2;
  background-color: #fff;
 span{
   opacity: 0.3;
 }
}
.ql-container.ql-snow {
  border: 1px solid #e7ecf2;
  background-color: #fff;
  height: 100px !important;

}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}
.evene-tabsset > ul {
  pointer-events: none;
}
.nb-theme-corporate{
  .booking-grid-left-part{
    nb-accordion-item-body {
      .item-body {
        padding: 15px;
      }
    }
  }
}

.list-custom {
  max-height: 400px;
  overflow: auto;
}
.tag-selected {
  min-height: 24px;
  height: 24px;
}
nb-select{
  padding: 0 !important;
  button{
    min-height: 45px !important;
  }
}


.ql-mention-list-container {
  width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 50px;
  font-size: 20px;
  padding: 0px 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}
.tippy-box {
  background-color: transparent !important;
}
.tippy-box:focus{
  outline: none !important;
}
.cap{
  text-transform: capitalize !important;
}
.permission_disabled {
  pointer-events: none;
  opacity: 0.5;
  z-index: 10;
}
.no-record-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.notification-loader nb-spinner {
  background-color: #f5f5f5 !important;
}
.service-option-list{
  width: auto !important;
}
