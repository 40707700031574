@import "color-palatte";
.common-font-family {
  font-family: "GoogleSans", sans-serif;
}

.common-text-center {
  text-align: center;
  justify-content: center;
}

.common-d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


$box-icon-hw: 30px;
$box-icon-br: 4px;
$box-icon-space: 10px;
$left-margin: 5px;
booking-grid {
  $min-height-time-slots: 60px;
  $min-width-time-slots: 60px;
  $min-height-resouce-slots: 50px;

  .fc .fc-timeline-lane-frame {
    height: 42px !important;
  }

  .fc-datagrid-cell-frame {
    height: 42px !important;
  }

  .fc-datagrid-cell {
    height: 42px !important;
  }

  .fc {

    @extend .common-font-family;

    .fc-resource-group .fc-cell-shaded {
      height: 42px !important;
    }

    .fc-timeline-header-row-chrono {
      .fc-timeline-slot-frame {
        min-height: $min-height-time-slots;
        min-width: $min-width-time-slots;
        @extend .common-text-center;

        a {
          text-decoration: none;
        }
      }
    }

    .fc-datagrid-header {
      .fc-datagrid-cell-frame {
        @extend .common-text-center;
        min-height: $min-height-time-slots;
        min-width: $min-width-time-slots;
      }
    }

    .fc-resource {
      .fc-datagrid-cell-cushion {
        padding: 5px;
        height: 42px !important;
      }
    }

    .fc-timeline-now-indicator-line {
      //border-left: 1px dashed $primary-color;
      border: none;
      width: 1px;
      z-index: 9999;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2334A853FF' stroke-width='4' stroke-dasharray='7%2c 18' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
    }

    .fc-h-event {
      background-color: transparent;
      border: none;
    }

    .fc-datagrid-expander {
      display: none;
    }

    .fc-event .fc-event-main {
      z-index: initial;
    }

    .fc-icon {
      display: none !important;
    }

    .fc-resource-timeline .fc-resource-group {
      height: 42px !important;
    }

    .fc-timeline-event-harness {
      height: 42px !important;
    }

    .fc-resource-timeline .fc-resource-group:not([rowspan]) {
      border: none;
      height: 43px !important;
      background-color: $background-color;
      z-index: 2;
      position: relative;
      color: $primary-color;
      font-weight: 600;
      text-align: center;
    }

    .fc-timeline-now-indicator-arrow {
      display: none;
    }

    .fc-timeline-event {
      padding: 0;
    }

    .booking-grid-list-inner {
      border-radius: 5px;
      padding: 5px;
      height: 42px;

      &.bg-green {
        background-color: #dff5e5;
      }


    }

    .private-function-inner {
      padding: 5px;
      background: #DCDCDC;
      height: 42px;
    }

    .join-to-parent {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;

      .join-to {
        background: #ffffff;
        border-radius: 4px;
        text-align: center;
        padding: 5px 10px;

        p {
          color: $font-common-dark;
          font-size: 13px;
          line-height: 16px;
          margin: 0;
        }
      }
    }

    .attach-icon {
      color: $primary-color;
    }

    .info-text-style {
      font-size: calc(var(--fontSize) - 4px);
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0px;
      color: $font-common-dark;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date p {
      @extend .info-text-style;
    }

    .title-list {
      p {
        @extend .info-text-style;
      }
    }

    .customer-name {
      p {
        @extend .info-text-style;
      }
    }

    .table-number {
      @extend .common-d-center;
      margin-left: $left-margin;
      width: $box-icon-hw;
      height: $box-icon-hw;
      border-radius: $box-icon-br;
      background-color: $font-common-dark;

      p {
        @extend .info-text-style;
        margin: 0;
        color: white;
      }
    }

    .first-icon {
      @extend .common-d-center;
      width: $box-icon-hw;
      height: $box-icon-hw;
      border-radius: $box-icon-br;
      background-color: $primary-color;
      margin-right: $box-icon-space;
    }

    .info-icons {
      width: calc(100% - #{($box-icon-hw * 2) + $left-margin});
      overflow: hidden;

      .icons {
        $common-hw: 17px;
        margin-left: 10px;

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: flex-end;
          width: 100%;

          li {
            @extend .common-d-center;
            background: #ffffff;
            margin-right: 3px;
            width: $common-hw;
            height: $common-hw;
            border-radius: 5px;

            &:last-of-type {
              margin-right: 0;
            }

            .material-icons-round {
              font-size: 12px;
            }
          }
        }
      }
    }

    .current-time {
      $width: 55px;
      background-color: $primary-color;
      font-size: 10px;
      padding: 2px;
      box-shadow: 0px 0px 3px #00000033;
      position: absolute;
      width: $width;
      text-align: center;
      border: 1px solid #FFFFFF;
      border-radius: 5px;
      color: white;
      left: - ($width / 2);
      top: 5px;
    }
  }

  .blockout-container {
    cursor: not-allowed !important;
    background-color: #e9ebec;
    min-height: 42px;
    height: 100%;
    display: flex;
    align-items: center;

    .info-icons {
      margin-left: 20px;
      width: 100%;
    }
  }

  .cus-time-slot-container {
    color: $input-placeholder-color;
  }

  .cus-resource-title {
    margin: 0;
    @extend .common-text-center;
    color: $font-common-dark;
    font-size: 16px;
  }

  .cus-resource-subtitle {
    margin: 0;
    @extend .common-text-center;
    color: $input-placeholder-color;
    font-size: 12px;
  }

}
booking-calender-view{
    .day-header {
      background-color: $background-color;
      height: 50px;
      color: $font-common-dark;
    }
    .fc {
      th {
        vertical-align: middle;
        a {
          text-decoration: none;
          color: $font-common-dark;
        }
      }

      .fc-daygrid-day-top {
        flex-direction: row;
      }

      .fc-daygrid-day-frame {
        display: flex;
        flex-direction: column-reverse;
        padding: 5px 10px;
      }

      .fc-daygrid-event {
        z-index: initial;
      }
    }

  .fc-event .fc-event-main {
    z-index: initial;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: #E8F5EB;
  }

  .day-cell {
    max-height: 94px;
    height: 94px;

    a {
      text-decoration: none;
      color: $font-common-dark;
      font-weight: 500;
    }
  }
  .event-main {
    background-color: transparent;
    border: none;
  }
  .event-status-icon {
    @extend .common-d-center;
    width: 27px;
    height: 27px;
    border-radius: $box-icon-br;
    background-color: $primary-color;
    border: 1px solid $border-color;
    box-shadow: 0px 0px 3px #00000029;

    img {
      height: 18px;
      width: 18px;
    }
  }

  .bg-green {
    background-color: $primary-color;
  }

  .bg-yellow {
    background-color: $secondary-color;
  }

  .bg-blue {
    background-color: $info-color;
  }

  .bg-gray {
    background-color: $font-common-dark;
  }

  .bg-red {
    background-color: $danger-color;
  }
}

#btn_back {
  background-color: transparent !important;
  border-color: $primary-color !important;
}
