@import "color-palatte";
@import "typography";
.primary-button {
  width: 172px;
  font-weight: 500;

}
.common-table-main-container {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  thead {
    color: $input-filled-color;
  }
  th {
    font-weight: 500;
    height: 2.5rem;
    position: sticky;
    top: 0;
    background-color: white;
  }
  td {
    font-family: $font-regular;
    font-weight: normal;
    color: $input-filled-color;
  }
  tr:nth-child(odd) {
    background-color: $table-row-gray;
  }
  .nb-theme-corporate nb-layout .layout {
    line-height: 1rem !important;
  }
  .table-column-cell {
    padding: 0.625rem;
  }
  .table-header {
    background-color: white !important;
  }
}
.common-form {
  label {
    font-weight: 500;
    font-size: var(--fontSize);
    color: $font-header-dark;
  }
  .common-input {
    width: 314px;
  }
  .common-input--wide {
    width: 480px;
  }
}
.form-sub-header {
  font-size: $form-sub-header;
  color: $font-header-dark;
  font-family: $font-bold;
}
.input-prefix {
  padding: 0.4375rem 0.313rem !important;
}
.header-left-container {
  width: 170px;
  background-color: $secondary-color;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100px;
  }
}
.header-middle-container {
  background-color: $primary-color;
  width: calc(100vw - 170px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
}
.header-middle {
  display: flex;
}
.menu-icon-container {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-icon-container:hover,
.menu-icon-container--selected {
  background-color: rgba($primary-light, 0.5);
  border-radius: 5px;
  .menu-icon {
    opacity: 1;
  }
}
.menu-icon-container:focus {
  outline: none;
}
.menu-icon {
  height: 24px;
  width: 24px;
  opacity: 0.5;
  color: white;
}
.right-menu-icon-container {
  height: 50px;
  width: 50px;
  background-color: rgba($primary-light, 0.5);
  border: 1px solid rgba(#ffffff, 0.5);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.right-menu-icon {
  height: 24px;
  width: 24px;
  color: white;
}
.right-menu-icon ~ span {
 font-size: 16px;
  padding: 11px 9px;
}
.profile-pic-main {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid $border-color;
  img {
    height: 48px;
    width: 48px;
    object-fit: cover;
    border-radius: 50%;
  }
}
.main-layout-box {
  height: calc(100vh - 76px);
  overflow: auto;
}
.menu-bar-icon {
  margin-right: -56px;
  box-shadow: 0px 0px 6px #51515140;
  border: none !important;
}
.admin-icon {
  background-color: $secondary-color;
  border-radius: $border-radius;
  .menu-icon {
    opacity: 1;
  }
}
.page-header-main {
  width: 100%;
  display: flex;
  padding: 18px 38px;
  justify-content: space-between;
  align-items: center;
}
.page-header {
  font-family: $font-bold;
  font-size: $h4-header-font-size;
  line-height: $header-line-height;
  color: $font-header-dark;
}
.breadcrumb-dark {
  font-weight: 500;
  color: $input-filled-color;
  font-size: $font-small;
  cursor: pointer;

}
.breadcrumb-dark:focus {
  outline: none;
}
.breadcrumb-light {
  font-weight: 500;
  color: $input-placeholder-color;
  font-size: $font-small;
}
.common-tabs {
  background-color: white;
  padding: 20px 40px;
  position: relative;
  z-index: 1;
  overflow: auto;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background-color: $background-color;
    z-index: -1;
  }
}
.rotate-icon {
  transform: rotate(90deg);
}
.accordion-outline-button {
  font-size: $font-small !important;
  padding: 0.5rem 0.75rem !important;
  border: 1px solid $primary-color !important;
  background-color: transparent !important;
  text-transform: none !important;
}
.accordion-primary-button {
  font-size: $font-small !important;
  padding: 0.5rem 0.75rem !important;
  border: 1px solid $primary-color !important;
  background-color: $primary-color !important;
}
.accordion-next-button {
  height: 34px;
  width: 34px;
}
.tab-container {
  height: calc(100vh - 250px);
  overflow: auto;
}
.horizontal-divider {
  height: 1px;
  width: 100%;
  margin-top: 18px;
  background-color: $border-color;
}
.input-prefix {
  padding: 0.4375rem 0.313rem !important;
}
.logo-container {
  height: 100px;
  width: 100px;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    max-width: 90px;
    max-height: 90px;
  }
}
.logo-container nb-spinner {
  background-color: white !important;
}
.editor-div {
  width: 600px;
}

.email-editor {
  width: 100%;
}

.common-list {
  .list-icon {
    display: flex;
    nb-icon {
      &:last-child {
        transform: translateX(-16px);
      }
    }
  }
  .list-title {
    h4 {
      text-align: left;
      font-size: var(--fontSize);
      line-height: 20px;
      letter-spacing: 0px;
      color: $input-filled-color;
      opacity: 1;
      margin: 0;
      font-weight: 500;
    }
  }
  nb-list-item {
    background-color: $background-color;
    border: 1px solid $border-color;
    margin-top: 10px;
    border-radius: 5px;
    height: 45px;
    cursor: pointer;
  }
  .list-action {
    display: flex;
    nb-icon {
      cursor: pointer;
    }
  }
}
.tabs-header{
  div{
    text-align: left;
    font-size: calc(var(--fontSize) + 4px);
    line-height: 25px;
    letter-spacing: 0px;
    color: $primary-color;
    opacity: 1;
    margin: 0;
  }
}
.form-label {
  font-weight: 500;
  font-size: 1rem;
  color: $input-filled-color;
}
.common-list-title-primary {
  text-align: left;
  font-size: calc(var(--fontSize) + 4px);
  line-height: 25px;
  color: $primary-color;
}

.nb-theme-corporate  nb-card {
  border: 0px;
  margin: 12px 0px;
  //nb-card-body {
  //  padding: 0px 0px;
  //}
}

.nb-theme-corporate {
  nb-select.size-medium {
    min-width: 100% !important;
    padding-right: 20px;
  }
}

.nb-theme-corporate nb-checkbox.status-primary .custom-checkbox {
  background-color: rgb(255, 255, 255);
}

.add-table-list {
  background: $primary-color 0% 0% no-repeat padding-box;
  border: 1px solid $border-color;
  border-radius: 5px;
  opacity: 1;
  padding: 9px;
  margin-right: 10px;
  p{
    color: white;
    font-size: calc(var(--fontSize) - 2px);
    line-height: 17px;
    margin-right: 5px !important;
    font-weight: 300;
  }
  nb-icon{
    width: 16px;
    height: 16px;
  }
  /* color: #fff; */
}


 .nb-theme-corporate .toggle-input nb-select.size-medium{
  padding:0px !important;
}

.nb-theme-corporate [nbButton].ac-save.appearance-filled.status-basic{
  background-color: $secondary-color;
  color:#FFFFFF;
}

@media screen and (max-width: 800px) {
  li.ng-star-inserted{
    height: fit-content !important;
  }
}
$left-width : 170px;
$top-height : 76px + 81px;
.booking-grid-view {
  background-color: white;
  display: flex;
  transition: all 1s ease-out;
}
.booking-grid-right-part {
  width: calc(100% - #{$left-width});
  height: calc(100vh - #{$top-height});
  overflow: auto ;
}

.icon-button {
  padding: 8px !important;
  nb-icon {
    height: 24px !important;
    width: 24px !important;
  }
  span {
    font-size: 20px;
  }

  height: 40px;
  width: 40px;
}
.booking-status-popover-container {
  background-color: white;
  box-shadow: 0px 0px 45px #37373729;
  border-radius: 10px 5px 10px 10px;
  width: 265px;
  padding: 1rem;
  max-height: 400px;
  overflow-y: auto;

  .header {
    font-size: $font-small;
    font-weight: 500;
    color: $font-common-dark;
  }
  .status-list {
    height: 38px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    align-items: center;
    display: flex;
    margin-bottom: 8px;
  }
  .icon-main {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    border: 1px solid $border-color;
    border-radius: $border-radius;
  }
  .status-title {
    font-size: 12px;
    font-weight: 500;
  }
}
.bottom-floating-menu {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 10;
  $icon-hw: 40px;

  .zoom-main-div {
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 6px #3B3B3B29;
    border-radius: 5px 0px 0px 5px;
    height: $icon-hw;

    .bottom-icon-div {
      display: flex;
      background-color: white;
      align-items: center;
      justify-content: center;
      width: $icon-hw;
      height: $icon-hw;
      cursor: pointer;
    }
    .bottom-icon-div:first-child {
      border-radius: 5px 0 0 5px;
    }
    .refresh-btn {
      cursor: pointer;
      background-color: $info-color;
      border-radius: 0px 5px 5px 0px;
    }
    .disable-zoom {
      cursor: not-allowed;
    }
  }
}
.delete_dialog{
  min-height: 150px;
  width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .icon{
    height: 80px;
    width: 80px;
  }
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #f2f5f8 !important;
}
