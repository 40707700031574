@import "color-palatte";
@import "typography";
.mw-100{
  max-width: 100% !important;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width:70%;
}
.h-100 {
  height: 100%;
}
.font-color-white {
  color: white !important;
}
.font-color-primary {
  color: $primary-color;
}
.font-color-dark {
  color:$font-header-dark;
}
// margin
.mt-0 {
  margin-top: 0 !important;
}
.mt-5 {
  margin-top: 0.313rem;
}
.mt-10 {
  margin-top: 0.625rem;
}
.mt-15 {
  margin-top: 0.938rem;
}
.mt-20 {
  margin-top: 1.25rem;
}
.mt-25 {
  margin-top: 1.563rem;
}
.mt-30 {
  margin-top: 1.875rem;
}
.mt-70 {
  margin-top: 4.375rem;
}
.ml-6 {
  margin-left: 0.375rem;
}
.ml-10 {
  margin-left: 0.625rem;
}
.ml-15 {
  margin-left: 0.938rem;
}
.ml-20 {
  margin-left: 1.25rem;
}
.ml-30 {
  margin-left: 1.875rem;
}
.ml-auto{
  margin-left: auto;
}
.mr-auto{
  margin-right: auto;
}
.mr-10{
  margin-right: 10px;
}
.m-0{
  margin: 0 !important;
}
.mr-0{
  margin-right: 0 !important;
}
.ml-0{
  margin-left:0px !important;
}
.mr-6 {
  margin-right: 6px;
}

.mb-10{
  margin-bottom: 10px !important;
}
.mb-20{
  margin-bottom: 20px !important;
}
.mb-0{
  margin-bottom: 0;
}
// padding
.pl-0{
  padding-left: 0 !important;
}
.pl-30 {
  padding-left: 1.875rem !important;
}
.pr-30 {
  padding-right: 1.875rem !important;
}
.pl-10 {
  padding-left: 0.625rem;
}

.pt-0{
  padding-top: 0 !important;
}
.p-0{
  padding: 0 !important;
}
.p-10{
  padding: 10px !important;
}
.p-15{
  padding: 15px;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-20 {
  padding-top: 1.25rem;
}
.pr-10{
  padding-right: 10px;
}

.pr-15{
  padding-right: 15px !important;
}
.px-4{
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.pl-15{
  padding-left: 15px !important;
}
  // text

  .text-right {
    text-align: end;
  }

  .text-center {
    text-align: center;
  }

  .font-regular {
    font-family: $font-regular;
  }

  .font-medium {
    font-weight: 500 !important;
  }

  .font-bold {
    font-family: $font-bold;
  }

  .font-dark {
    color: $input-filled-color !important;
  }

  .font-light {
    color: $input-placeholder-color;
  }

  .font-14 {
    font-size: 0.875rem;
  }
.font-12 {
  font-size: 0.875rem;
}
.font-18{
  font-size: 18px;
}

  .cursor-pointer {
    cursor: pointer;
  }

  .border-none {
    border: none !important;
  }

  // display

  .d-flex {
    display: flex;
  }

  .f-wrap{
    flex-wrap: wrap;
  }

  .align-center {
    align-items: center;
  }

  .align-start {
    align-items: flex-start;
  }

  .just-center {
    justify-content: center;
  }

  .just-between {
    justify-content: space-between;
  }

  .just-end {
    justify-content: flex-end;
  }
  .f-wrap {
    flex-wrap: wrap;
  }
  .d-grid {
    display: grid;
  }
  .gap-20 {
    gap: 1.25rem;
  }
  .gap-10 {
    gap:0.625rem;
  }
.gap-5 {
  gap:0.313rem;
}
  .col-width-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .col-width-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .col-gap-16 {
    gap: 1rem;
  }

  // position

  .position-relative {
    position: relative;
  }

  //grid column
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .col-12 {
    max-width: 100%;
    flex: 0 0 100%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-11 {
    max-width: 91.666%;
    flex: 0 0 91.666%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-10 {
    max-width: 83.3333333%;
    flex: 0 0 83.3333333%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-9 {
    max-width: 75%;
    flex: 0 0 75%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-8 {
    max-width: 66.6666667%;
    flex: 0 0 66.6666667%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-7 {
    max-width: 58.3333333%;
    flex: 0 0 58.3333333%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-6 {
    max-width: 50%;
    flex: 0 0 50%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-5 {
    max-width: 41.6666667%;
    flex: 0 0 41.6666667%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-3 {
    max-width: 25%;
    flex: 0 0 25%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-2 {
    max-width: 16.66666%;
    flex: 0 0 16.66666%;
    width: 100%;
    padding: 0px 15px;
  }

  .col-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
    width: 100%;
    padding: 0px 15px;
  }

  //cursor
  .c-p {
    cursor: pointer;
  }

  .position-absolute {
    position: absolute;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .nb-theme-corporate .nb-timepicker-container {
    width: 20rem;
    height: 18rem;
  }

